import { HiOutlineChevronRight } from 'react-icons/hi'
import { Link } from 'react-router-dom'

export default function PaymentList({
	dateCreated,
	status,
	id,
	profilePic,
	fullName,
}) {
	return (
		<Link
			to={id}
			className='p-2 hover:bg-gray-200 border-b flex justify-between items-center'>
			<div className='flex'>
				<div className='flex mr-1'>
					<div className='border h-10 w-10 rounded-full bg-gray-300'>
						{profilePic && (
							<img
								src={profilePic}
								alt='pic'
								className='h-10 flex justify-center items-center object-cover rounded-full w-10'
							/>
						)}
					</div>
				</div>
				<div className='text-sm'>
					<p>{fullName}</p>
					<p className='text-xs text-gray-400'>{dateCreated}</p>
				</div>
			</div>
			<div className='flex items-center'>
				<div className='text-xs flex flex-col items-center'>
					<div
						className={`border h-6 w-6 rounded-full ${
							status === 'Approved' ? 'bg-green-500' : 'bg-amber-400'
						}`}></div>
					<p>{status}</p>
				</div>
				<HiOutlineChevronRight className='text-2xl' />
			</div>
		</Link>
	)
}
