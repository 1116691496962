import { HiOutlineChevronRight } from 'react-icons/hi'
import { Link } from 'react-router-dom'

export default function SalesList({ fullName, id, profilePic, userId }) {
	return (
		<Link
			to={`${id}/${userId}`}
			className='w-full p-2 hover:bg-gray-200 border-b flex justify-between items-center'>
			<div className='flex items-center'>
				<div className='flex mr-1'>
					<div className='border h-10 w-10 rounded-full bg-gray-300'>
						<img
							src={profilePic}
							alt='pic'
							className='h-10 flex justify-center items-center object-cover rounded-full w-10'
						/>
					</div>
				</div>
				<p>{fullName}</p>
			</div>
			<HiOutlineChevronRight className='text-2xl' />
		</Link>
	)
}
