import React from 'react'
import Navbar from '../../components/Navbar'

export default function Chats() {
	return (
		<div>
			<Navbar title='Latest chats' />
			Chats
		</div>
	)
}
