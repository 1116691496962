import { createContext, useReducer, useEffect } from 'react'
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore'
import { db } from '../firebase/config'
export const SalesContext = createContext()

const initialState = {
	isLoading: false,
	sales: [],
	errorMessage: null,
}

const salesReducer = (state, action) => {
	switch (action.type) {
		case 'LOADING':
			return { ...state, isLoading: true }
		case 'SALES':
			return { isLoading: false, sales: action.payload, errorMessage: null }
		case 'ERROR':
			return { ...state, errorMessage: action.payload }
		default:
			return state
	}
}
export const SalesCtxProvider = ({ children }) => {
	const [state, dispatch] = useReducer(salesReducer, initialState)

	useEffect(
		() =>
			(() => {
				const colRef = collection(db, 'orders')
				const ref = query(colRef, orderBy('timestamp', 'desc'))
				const unsubscribe = onSnapshot(ref, snapshot => {
					let results = []
					snapshot.docs.forEach(async doc => {
						const order = doc?.data()
						results.push({
							...{ id: doc.id, ...doc.data(), orderStatus: order.status },
						})

						if (results.length) {
							dispatch({
								type: 'SALES',
								payload: results,
							})
						}
					})
				})
				return unsubscribe
			})(),

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch]
	)

	return (
		<SalesContext.Provider value={{ ...state, dispatch }}>
			{children}
		</SalesContext.Provider>
	)
}
