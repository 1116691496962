import { useNavigate, useParams } from 'react-router-dom'
import Navbar from '../../components/Navbar'
import { useOrderContext } from '../../hooks/useOrderContext'
import { BsCheckCircleFill, BsFillTrashFill } from 'react-icons/bs'
import { AiFillWarning } from 'react-icons/ai'
import { useState } from 'react'
import { storage } from '../../firebase/config'
import { v4 as uuid } from 'uuid'
import OrderModal from '../../components/OrderModal'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { useFirestore } from '../../hooks/useFirestore'
import PendingOrder from './PendingOrder'
import ConfirmAddress from './ConfirmAddress'
import ConfirmDispatch from './ConfirmDispatch'
import CloseOrder from './CloseOrder'
import { useEffect } from 'react'

export default function OrderDetails() {
	const { id } = useParams()
	const { orders } = useOrderContext()
	const [pickedItem, setPickedItem] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [errors, setErrors] = useState('')
	const [selectedImages, setSelectedImages] = useState([])
	const [imageUrls, setImageUrls] = useState([])
	const [previewImage, setPreviewImage] = useState([])
	const [openOptions, setOpenOptions] = useState(false)
	const [isSubmitted, setIsSubmitted] = useState(false)
	const orderItem = orders?.filter(order => order.id === id)
	const { updateDocument } = useFirestore('orders')
	const navigation = useNavigate()

	const selectorButton = id => {
		setErrors('')
		if (pickedItem.includes(id)) {
			setPickedItem(prevState => prevState.filter(item => item !== id))
		} else {
			setPickedItem(prevState => [...prevState, id])
		}
	}

	const handleFileChange = e => {
		setIsLoading(true)
		setErrors('')
		const selectedImage = e.target.files
		const selectedFilesArray = Array.from(selectedImage)
		const imageArray = selectedFilesArray.map(image =>
			URL.createObjectURL(image)
		)

		for (let i = 0; i < e.target.files.length; i++) {
			const selectedImage = e.target.files[i]
			selectedImage['id'] = uuid()
			setSelectedImages(prev => [...prev, selectedImage])
		}
		setPreviewImage(prev => prev.concat(imageArray))
		setIsLoading(false)
	}

	const handleSubmit = async e => {
		e.preventDefault()
		setIsLoading(true)
		setImageUrls([])
		setErrors('')

		if (!pickedItem.length) {
			setErrors('tick order items on the check list')
			setIsLoading(false)
			return
		}
		if (!previewImage.length) {
			setErrors('upload at least one picture')
			setIsLoading(false)
			return
		}

		const promises = []

		selectedImages.forEach(async image => {
			const imagePathRef = ref(storage, `confirmPhotos/${image.name + uuid()}`)
			const uploadTask = await uploadBytesResumable(imagePathRef, image)
				.then(() => getDownloadURL(imagePathRef))
				.then(url => setImageUrls(prev => [...prev, url]))
				.catch(e => setErrors(e.message))

			promises.push(uploadTask)
		})

		await Promise.all(promises).then(() => setIsSubmitted(true))
	}

	useEffect(() => {
		if (imageUrls.length === previewImage.length && isSubmitted) {
			;(async () => {
				await updateDocument(id, {
					confirmPhoto: imageUrls[0],
					confirmed: 'Pending',
					photos: imageUrls,
					isRejected: false,
				})
					.then(() => setIsLoading(false))
					.then(() => navigation(-1))
			})()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [imageUrls, isSubmitted])

	const handleDelete = id => {
		setPreviewImage(previewImage.filter(item => item !== id))
	}

	return (
		<div>
			<Navbar title='Approved order' />
			{orderItem?.map(item => (
				<div className='flex flex-col p-1 w-full' key={item.id}>
					{item.confirmed === 'Pending' && <PendingOrder />}
					{item.confirmed && item.stage === 2 && (
						<ConfirmAddress id={id} confirmAddress={item.address} />
					)}
					{item.confirmed && item.stage === 4 && (
						<ConfirmDispatch id={item.id} />
					)}
					{item.confirmed && item.stage >= 5 && (
						<CloseOrder stage={item.stage} />
					)}
					{!item.confirmed && (
						<div>
							<div className='flex flex-col w-full justify-center items-center mb-2'>
								{item.isRejected && (
									<div className='text-xs my-1 py-1 items-center flex px-3 bg-yellow-500 rounded-full'>
										<AiFillWarning className='text-white mr-1 text-lg' />
										<p>Client rejected, please double check and re-submit</p>
									</div>
								)}
								<div className='flex rounded-md bg-pink-600 p-1 w-fit justify-center items-center m-2'>
									<input
										className=''
										type='file'
										accept='image/png, image/jpg, image/jpeg'
										multiple
										required
										onChange={handleFileChange}
									/>
								</div>
								<p className='text-xs my-1'>Attach product pics</p>
								<div className='flex w-full flex-wrap'>
									{previewImage &&
										previewImage.map(image => (
											<div key={image} className='relative'>
												<img
													src={image}
													alt='images'
													className='w-16 h-16 m-1 rounded-full border'
												/>
												<button
													onClick={() => handleDelete(image)}
													className='bg-red-500 rounded-full w-6 h-6 absolute bottom-0 justify-center items-center flex right-0'>
													<BsFillTrashFill className='text-white text-sm' />
												</button>
											</div>
										))}
								</div>
							</div>
							<div className='flex flex-col items-center w-full p-3 bg-gray-200'>
								<p>Order Details</p>
								<p className='text-xs'>
									Check all items on the order to proceed
								</p>
								{openOptions && (
									<OrderModal
										pickedItem={pickedItem}
										selectorButton={selectorButton}
										setOpenOptions={setOpenOptions}
										products={item.listedItems}
									/>
								)}
								<div className='mt-5 w-full'>
									{item?.orders.map((order, index) => (
										<div
											className='bg-white my-2 px-3 py-1 text-sm items-center flex justify-between rounded-xl'
											key={order.id}>
											<div className='flex'>
												<p className='mr-2'>{index + 1}.</p>
												<p>{order.name}</p>
											</div>
											<div className='flex'>
												<p className='mr-2'>{order.quantity}</p>
												<button
													onClick={() =>
														item.listedItems.length && order.isListed
															? setOpenOptions(true)
															: selectorButton(order.id)
													}>
													<BsCheckCircleFill
														className={`text-xl ${
															pickedItem.includes(order.id)
																? 'text-green-500'
																: 'text-gray-300'
														}`}
													/>
												</button>
											</div>
										</div>
									))}
								</div>
								{errors && (
									<p className='text-xs mt-3 text-red-500'>{errors}</p>
								)}
								<button
									disabled={isLoading}
									onClick={handleSubmit}
									className='submitBtn'>
									{isLoading ? 'Loading' : 'Submit'}
								</button>
							</div>
						</div>
					)}
				</div>
			))}
		</div>
	)
}
