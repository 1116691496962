import { GrDocumentLocked } from 'react-icons/gr'

export default function NoAccess() {
	return (
		<div className='flex justify-center items-center pt-10'>
			<div className='flex flex-col items-center'>
				<GrDocumentLocked className='text-7xl mb-3 text-gray-400' />
				<p className='text-gray-500 text-sm text-center'>
					Oops! you do not have permissions
				</p>
				<p className='text-gray-500 text-sm text-center'>to access this page</p>
			</div>
		</div>
	)
}
