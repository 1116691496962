import logo from '../assets/havillah_tm.png'
// import { HiOutlineDotsCircleHorizontal } from 'react-icons/hi'

export default function Header() {
	return (
		<div className='bg-pink-500 flex flex-col items-center p-2 justify-center'>
			<img src={logo} className='h-10 object-contain' alt='havillah_logo' />
			{/* <div className='cursor-pointer flex flex-col items-center right-0 mr-3 top-3'>
				<HiOutlineDotsCircleHorizontal className='text-2xl text-pink-900' />
			</div> */}
		</div>
	)
}
