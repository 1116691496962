import { AiOutlineFileProtect } from 'react-icons/ai'

export default function EmptyState() {
	return (
		<div className='flex justify-center items-center pt-10'>
			<div className='flex flex-col items-center'>
				<AiOutlineFileProtect className='text-9xl text-gray-400' />
				<p>No new users to register</p>
			</div>
		</div>
	)
}
