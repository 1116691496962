import { useParams } from 'react-router-dom'
import Navbar from '../../components/Navbar'
import { useRegisterContext } from '../../hooks/useRegisterContext'
import { useState } from 'react'
import { collection, doc, updateDoc } from 'firebase/firestore'
import { db } from '../../firebase/config'

export default function RegistrationDetails() {
	const { answers } = useRegisterContext()
	const [errors, setErrors] = useState('')
	const { id, userId } = useParams()

	const handleSubmit = async status => {
		try {
			const colRef = collection(db, 'userDetails')
			const docRef = doc(colRef, userId)
			if (status === 'Approved') {
				await updateDoc(docRef, { status: status })
				await updateDoc(doc(collection(db, 'registrations'), id), {
					regStatus: status,
				})
			} else if (status === 'Rejected') {
				await updateDoc(docRef, { status: status })
				await updateDoc(doc(collection(db, 'registrations'), id), {
					regStatus: status,
				})
			}
			window.location = '/'
		} catch (error) {
			console.log(error)
			setErrors(error)
		}
	}

	return (
		<div>
			<Navbar title='Registered user' />
			{answers?.map(({ answer, question }) => (
				<div
					className='flex p-1 justify-between space-x-2 border-b'
					key={question}>
					<div className='flex-col flex  w-full'>
						<p className='text-xs text-gray-500'>Question:</p>
						<p className='text-sm bg-gray-200 px-1 rounded-sm'>{question}</p>
						<p className='text-xs text-gray-500'>Answer:</p>
						<p className='text-sm px-1 text-orange-500'>{answer}</p>
					</div>
				</div>
			))}
			{errors && (
				<p className='text-xs text-red-400 mt-3 text-center'>{errors}</p>
			)}
			<div className='flex w-3/4 mx-auto mt-3'>
				<button
					onClick={() => handleSubmit('Approved')}
					className='submitBtn bg-green-500'>
					Approve
				</button>
				<button
					onClick={() => handleSubmit('Rejected')}
					className='submitBtn bg-red-500'>
					Reject
				</button>
			</div>
		</div>
	)
}
