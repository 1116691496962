import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom'
import Dashboard from '../pages/Dashboard'
import Login from '../pages/LoginScreen'
import ResetPassword from '../pages/ResetPassword'
import PaymentList from '../pages/Payments'
import OrderDetails from '../pages/Orders/OrderDetails'
import PaymentDetails from '../pages/Payments/PaymentDetails'
import SaleDetail from '../pages/Sales/SaleDetail'
import SaleProfile from '../pages/Sales/SaleProfile'
import Workshops from '../pages/Workshops'
import Orders from '../pages/Orders'
import Sales from '../pages/Sales'
import Chats from '../pages/Chats'
import Registrations from '../pages/Registrations'
import RegistrationDetails from '../pages/Registrations/RegistrationDetails'
import Header from '../components/Header'

export default function RootRoutes({ isAuthReady, user }) {
	return (
		<BrowserRouter>
			{isAuthReady && (
				<div className='bg-white min-h-screen'>
					<Header />
					<Routes>
						<Route
							path='/'
							element={user ? <Dashboard /> : <Navigate to='/login' />}
						/>
						<Route
							path='/payments'
							element={user ? <PaymentList /> : <Navigate to='/login' />}
						/>
						<Route
							path='/payments/:id'
							element={user ? <PaymentDetails /> : <Navigate to='/login' />}
						/>
						<Route
							path='/orders'
							element={user ? <Orders /> : <Navigate to='/login' />}
						/>
						<Route
							path='/orders/:id'
							element={user ? <OrderDetails /> : <Navigate to='/login' />}
						/>
						<Route
							path='/sales'
							element={user ? <Sales /> : <Navigate to='/login' />}
						/>
						<Route
							path='/sales/:id/:userId'
							element={user ? <SaleProfile /> : <Navigate to='/login' />}
						/>
						<Route
							path='/sales/:id/:userId/:saleId'
							element={user ? <SaleDetail /> : <Navigate to='/login' />}
						/>
						<Route
							path='/chats'
							element={user ? <Chats /> : <Navigate to='/login' />}
						/>
						<Route
							path='/registration'
							element={user ? <Registrations /> : <Navigate to='/login' />}
						/>
						<Route
							path='/registration/:id/:userId'
							element={
								user ? <RegistrationDetails /> : <Navigate to='/login' />
							}
						/>
						<Route
							path='/workshops'
							element={user ? <Workshops /> : <Navigate to='/login' />}
						/>
						<Route
							path='/login'
							element={user ? <Navigate to='/' /> : <Login />}
						/>
						<Route
							path='/reset'
							element={user ? <Navigate to='/' /> : <ResetPassword />}
						/>
					</Routes>
				</div>
			)}
		</BrowserRouter>
	)
}
