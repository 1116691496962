import { useState } from 'react'
import Navbar from '../../components/Navbar'
import NoAccess from '../../components/NoAccess'
import { useOrderContext } from '../../hooks/useOrderContext'
import { useUserContext } from '../../hooks/useUserContext'
import OrderList from './OrderList'

export default function Orders() {
	const [search, setSearch] = useState('')
	const { orders } = useOrderContext()
	const { activeUser } = useUserContext()

	const approvedOrders = orders?.filter(
		order => order.orderStatus === 'Approved'
	)

	return (
		<div>
			<Navbar title='Latest orders' />
			{activeUser?.role !== 'Admin' ? (
				<NoAccess />
			) : (
				<div className='p-2'>
					<div className='flex items-center justify-between border-b pb-1'>
						<input
							className='m-1 border w-full rounded-full pl-2 text-gray-400 py-1 outline-none'
							placeholder='search...'
							value={search}
							onChange={e => setSearch(e.target.value)}
						/>
						Search
					</div>
					<div className='flex justify-center text-xs bg-gray-300 p-1 space-x-2 mb-1'>
						<p>
							<span className='bg-green-500 px-3' />
							{'\xa0'} needs your attention
						</p>
						<p>
							<span className='bg-amber-400 px-3' />
							{'\xa0'}waiting for client
						</p>
						<p>
							<span className='bg-gray-500 px-3' />
							{'\xa0'}order completed
						</p>
					</div>
					{approvedOrders.map(order => (
						<OrderList
							key={order.id}
							confirmed={order.confirmed}
							id={order.id}
							stage={order.stage}
							dateCreated={order.dateCreated}
							timestamp={order.createdAt}
							orderNumber={order.orderNumber}
						/>
					))}
				</div>
			)}
		</div>
	)
}
