import { createContext, useReducer } from 'react'

export const PaymentContext = createContext()

const initialState = {
	isLoading: false,
	payments: [],
	errorMessage: null,
}

const paymentReducer = (state, action) => {
	switch (action.type) {
		case 'LOADING':
			return { ...state, isLoading: true }
		case 'PAYMENTS':
			return { isLoading: false, payments: action.payload, errorMessage: null }
		case 'ERROR':
			return { ...state, errorMessage: action.payload }
		default:
			return state
	}
}

export const PaymentCxtProvider = ({ children }) => {
	const [state, dispatch] = useReducer(paymentReducer, initialState)

	return (
		<PaymentContext.Provider value={{ ...state, dispatch }}>
			{children}
		</PaymentContext.Provider>
	)
}
