import React from 'react'
import Navbar from '../../components/Navbar'
import NoAccess from '../../components/NoAccess'
import { useUserContext } from '../../hooks/useUserContext'
import EmptyState from './EmptyState'
import { useRegisterContext } from '../../hooks/useRegisterContext'
import RegistrationList from './RegistrationList'

export default function Registrations() {
	const { activeUser } = useUserContext()
	const { registrations } = useRegisterContext()

	const pendingRegUsers = registrations.filter(
		user => user.results && user.regStatus === 'Pending'
	)

	return (
		<div>
			<Navbar title='Pending registrations' />
			{activeUser?.role !== 'Admin' ? (
				<NoAccess />
			) : (
				<>
					{!pendingRegUsers.length ? (
						<EmptyState />
					) : (
						pendingRegUsers.map(user => (
							<RegistrationList
								key={user.docID}
								id={user.docID}
								username={user.email || user.username}
								profilePic={user.profilePic}
								userId={user.randomUserID}
								status={user.regStatus}
								results={user.results}
							/>
						))
					)}
				</>
			)}
		</div>
	)
}
