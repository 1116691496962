import { useState } from 'react'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useAuthContext } from './useAuthContext'
import { auth } from '../firebase/config'

export const useLogin = () => {
	const { dispatch } = useAuthContext()
	const [error, setError] = useState(null)
	const [isPending, setIsPending] = useState(false)

	const login = async (email, password) => {
		setError(null)
		setIsPending(true)
		try {
			const res = await signInWithEmailAndPassword(auth, email, password)
			dispatch({
				type: 'LOGIN',
				payload: res.user,
			})
			setIsPending(false)
		} catch (error) {
			setIsPending(false)
			setError(error.message)
			console.log(error.message)
		}
	}

	return { login, error, isPending }
}
