import { createContext, useReducer } from 'react'

export const UserContext = createContext()

const initialState = {
	activeUser: null,
}

const activeUserReducer = (state, action) => {
	switch (action.type) {
		case 'LOAD_USER':
			return { activeUser: action.payload }
		case 'REMOVE_USER':
			return { activeUser: null }
		default:
			return state
	}
}

export const UserCtxProvider = ({ children }) => {
	const [state, dispatch] = useReducer(activeUserReducer, initialState)

	return (
		<UserContext.Provider value={{ ...state, dispatch }}>
			{children}
		</UserContext.Provider>
	)
}
