import { createContext, useReducer, useEffect } from 'react'
import {
	collection,
	getDocs,
	onSnapshot,
	orderBy,
	query,
	limit,
	where,
} from 'firebase/firestore'
import { db } from '../firebase/config'
import { useCallback } from 'react'

export const OrderContext = createContext()

const initialState = {
	isLoading: false,
	orders: [],
	errorMessage: null,
}

const orderReducer = (state, action) => {
	switch (action.type) {
		case 'LOADING':
			return { ...state, isLoading: true }
		case 'ORDERS':
			return { isLoading: false, errorMessage: null, orders: action.payload }
		case 'ERROR':
			return { ...state, errorMessage: action.payload }
		default:
			return state
	}
}

export const OrderCxtProvider = ({ children }) => {
	const [state, dispatch] = useReducer(orderReducer, initialState)

	const fetchUserDetails = useCallback(async id => {
		const q = query(
			collection(db, 'userDetails'),
			where('randomUserID', '==', id)
		)

		const querySnapshot = await getDocs(q)
		let details = {}
		querySnapshot.forEach(doc => {
			details = { ...doc.data() }
		})

		return details
	}, [])

	useEffect(
		() =>
			(() => {
				const colRef = collection(db, 'orders')
				const ref = query(colRef, orderBy('timestamp', 'desc'), limit(50))
				const unsubscribe = onSnapshot(ref, snapshot => {
					let results = []
					snapshot.docs.forEach(async doc => {
						const order = doc?.data()
						const userInfo = await fetchUserDetails(order.randomUserID)
						results.push({
							...{ id: doc.id, ...doc.data(), orderStatus: order.status },
							...userInfo,
						})
						if (results.length) {
							dispatch({
								type: 'ORDERS',
								payload: results,
							})
						}
					})
				})
				return unsubscribe
			})(),

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch]
	)

	return (
		<OrderContext.Provider value={{ ...state, dispatch }}>
			{children}
		</OrderContext.Provider>
	)
}
