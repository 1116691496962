import {
	collection,
	getDocs,
	onSnapshot,
	query,
	where,
} from 'firebase/firestore'
import { createContext, useCallback, useEffect, useReducer } from 'react'
import { db } from '../firebase/config'

export const RegisterContext = createContext()

const initialState = {
	isLoading: false,
	registrations: [],
	errorMessage: null,
	answers: [],
}

const registerReducer = (state, action) => {
	switch (action.type) {
		case 'LOADING':
			return { ...state, isLoading: true }
		case 'REGISTRATIONS':
			return {
				isLoading: false,
				registrations: action.payload,
				errorMessage: null,
			}
		case 'ANSWERS':
			return { ...state, answers: action.payload }
		case 'ERROR':
			return { ...state, errorMessage: action.payload }
		default:
			return state
	}
}

export const RegisterCxtProvider = ({ children }) => {
	const [state, dispatch] = useReducer(registerReducer, initialState)

	const fetchUserDetails = useCallback(async id => {
		const q = query(
			collection(db, 'registrations'),
			where('randomUserID', '==', id)
		)

		const querySnapshot = await getDocs(q)
		let details = {}
		querySnapshot.forEach(doc => {
			details = { ...doc.data(), docID: doc.id }
		})

		return details
	}, [])

	useEffect(() => {
		const colRef = collection(db, 'userDetails')
		const unsubscribe = onSnapshot(colRef, snapshot => {
			let results = []
			snapshot.docs.forEach(async doc => {
				const user = doc?.data()
				const userInfo = await fetchUserDetails(user.userID)
				results.push({
					...{ ...doc.data() },
					...userInfo,
				})
				if (results.length) {
					dispatch({
						type: 'REGISTRATIONS',
						payload: results,
					})
				}
			})
		})
		return unsubscribe
	}, [dispatch, fetchUserDetails])

	return (
		<RegisterContext.Provider value={{ ...state, dispatch }}>
			{children}
		</RegisterContext.Provider>
	)
}
