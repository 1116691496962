import { BsCheckCircleFill, BsXCircleFill } from 'react-icons/bs'

export default function OrderModal({
	setOpenOptions,
	products,
	pickedItem,
	selectorButton,
}) {
	return (
		<div className='modalOverlay'>
			<div className='modalCover'>
				<div className='bg-gray-300 p-2 rounded-t-md flex items-center justify-between'>
					<p>Check products</p>
					<BsXCircleFill
						onClick={() => setOpenOptions(false)}
						className='text-3xl text-gray-600 hover:text-gray-500 cursor-pointer'
					/>
				</div>
				<div className='p-3 flex-col flex items-center'>
					{products.map(item => (
						<div
							key={item.id}
							className='flex justify-between bg-gray-100 px-2 my-1 w-full'>
							<p>{item.col}</p>
							<div className='flex space-x-2'>
								<p>{item.quantity}</p>
								<button onClick={() => selectorButton(item.id)}>
									<BsCheckCircleFill
										className={`text-xl ${
											pickedItem.includes(item.id)
												? 'text-green-500'
												: 'text-gray-300'
										}`}
									/>
								</button>
							</div>
						</div>
					))}
					<div className='border-b mt-5 w-full'></div>
					<button onClick={() => setOpenOptions(false)} className='submitBtn'>
						Submit
					</button>
				</div>
			</div>
		</div>
	)
}
