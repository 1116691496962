import { createContext, useEffect, useReducer } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '../firebase/config'

export const AuthContext = createContext()

const initialState = {
	isAuthReady: false,
	user: null,
}

export const authReducer = (state, action) => {
	switch (action.type) {
		case 'LOGIN':
			return { ...state, user: action.payload }
		case 'IS_AUTH_READY':
			return { isAuthReady: true, user: action.payload }
		case 'LOGOUT':
			return { ...state, user: null }
		default:
			return state
	}
}

export const AuthContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(authReducer, initialState)

	useEffect(
		() =>
			onAuthStateChanged(auth, user => {
				dispatch({
					type: 'IS_AUTH_READY',
					payload: user,
				})
			}),
		[]
	)

	return (
		<AuthContext.Provider value={{ ...state, dispatch }}>
			{children}
		</AuthContext.Provider>
	)
}
