import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore, serverTimestamp } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
	apiKey: 'AIzaSyBjcf_1MOqhSrDsD-jKnTgix2-77pahWbs',
	authDomain: 'havillah-beauty.firebaseapp.com',
	projectId: 'havillah-beauty',
	storageBucket: 'havillah-beauty.appspot.com',
	messagingSenderId: '363079291997',
	appId: '1:363079291997:web:606777460c643df1d2e9b4',
	measurementId: 'G-CR90MZEDPG',
}

initializeApp(firebaseConfig)

export const auth = getAuth()
export const db = getFirestore()
export const storage = getStorage()
export const timestamp = serverTimestamp()
