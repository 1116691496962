import { increment } from 'firebase/firestore'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import delivery from '../../assets/delivery.png'
import { useFirestore } from '../../hooks/useFirestore'

export default function ConfirmDispatch({ id }) {
	const { updateDocument } = useFirestore('orders')
	const navigation = useNavigate()
	const handleSubmit = e => {
		e.preventDefault()
		updateDocument(id, { stage: increment(1) }).then(() => navigation(-1))
	}

	return (
		<div className='w-full flex items-center justify-center flex-col'>
			<div className='flex w-40 h-40 p-2 my-5 rounded-full shadow-sm bg-gray-100'>
				<img src={delivery} alt='pic' />
			</div>
			<p>Confirm if the order has been</p>
			<p>collected for delivery.</p>
			<button onClick={handleSubmit} className='submitBtn'>
				Collected
			</button>
		</div>
	)
}
