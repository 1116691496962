import { Link } from 'react-router-dom'
import config from '../../package.json'
import LoadingModal from '../components/LoadingModal'
import { useUserContext } from '../hooks/useUserContext'

const data = [
	{ name: 'Payments', id: 3, navigate: 'payments' },
	{ name: 'Orders', id: 2, navigate: 'orders' },
	{ name: 'Sales', id: 4, navigate: 'sales' },
	{ name: 'Chats', id: 1, navigate: 'chats' },
	{ name: 'Registrations', id: 5, navigate: 'registration' },
	{ name: 'Workshops', id: 6, navigate: 'workshops' },
]
export default function Dashboard() {
	const { activeUser } = useUserContext()
	const now = new Date().getHours()

	const getTime = () => {
		if (now < 12) {
			return 'Good morning ,'
		} else if (now >= 12 && now < 18) {
			return 'Good afternoon ,'
		} else if (now >= 18) {
			return 'Good evening ,'
		}
	}

	if (!activeUser) {
		return <LoadingModal />
	}

	return (
		<div className=' flex flex-col'>
			<div className='bg-pink-500 pt-2   flex items-center text-white'>
				<div className='w-24 border shadow-md h-24 rounded-full flex justify-center items-center m-2'>
					<img
						src={activeUser?.profilePic}
						alt='profilePic'
						className='w-20 h-20 object-cover flex justify-center items-center rounded-full'
					/>
				</div>
				<div className=''>
					<div className=''>{getTime()}</div>
					<div className=''>{activeUser?.fullName}</div>
				</div>
			</div>
			<div className='grid grid-cols-2 p-2 place-items-center'>
				{data.map(item => (
					<Link
						to={item.navigate}
						key={item.id}
						className={`border h-32 w-32 m-2 flex justify-center items-center p-2 rounded-md shadow-sm`}>
						{item.name}
					</Link>
				))}
			</div>
			<p className='flex justify-center m-2 text-sm'>
				version {config.version}
			</p>
		</div>
	)
}
