import React from 'react'
import Navbar from '../../components/Navbar'
import PaymentList from './PaymentList'
import { useOrderContext } from '../../hooks/useOrderContext'
import { useUserContext } from '../../hooks/useUserContext'
import NoAccess from '../../components/NoAccess'

export default function Payment() {
	const { orders } = useOrderContext()
	const { activeUser } = useUserContext()

	orders.sort(function (a, b) {
		return b.timeCreated - a.timeCreated
	})
	const filteredOrders = orders.filter(item => item.orderStatus !== 'Rejected')

	return (
		<div>
			<Navbar title='Latest payments' />
			{activeUser?.role !== 'Admin' ? (
				<NoAccess />
			) : (
				<>
					{filteredOrders.map(item => (
						<PaymentList
							key={item.id}
							id={item.id}
							status={item.orderStatus}
							dateCreated={item.dateCreated}
							fullName={item.fullName}
							profilePic={item.profilePic}
						/>
					))}
				</>
			)}
		</div>
	)
}
