import { useState } from 'react'
import { Link } from 'react-router-dom'
import MobileLinks from '../components/MobileLinks'
import { useLogin } from '../hooks/useLogin'

export default function Login() {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const { isPending, error, login } = useLogin()

	const handleSubmit = e => {
		e.preventDefault()
		login(email, password)
	}

	return (
		<>
			<div className='flex flex-col my-20 mx-5 px-5 py-10 max-w-md rounded-sm bg-white shadow-md border border-gray-200'>
				<h2 className='text-2xl mb-3'>Login</h2>
				<form onSubmit={handleSubmit} className='flex flex-col'>
					<label className='flex flex-col space-y-1 my-2'>
						<span>email:</span>
						<input
							className='border p-1 rounded-md outline-none'
							type='email'
							required
							onChange={e => setEmail(e.target.value)}
							value={email}
						/>
					</label>
					<label className='flex flex-col space-y-1 my-2'>
						<span>password:</span>
						<input
							className='border p-1 rounded-md outline-none'
							type='password'
							required
							onChange={e => setPassword(e.target.value)}
							value={password}
						/>
					</label>
					{error && (
						<p className='text-sm text-red-800 text-center my-2'>{error}</p>
					)}
					<button className='px-7 py-1 rounded-md border-pink-500 border-2 self-center'>
						{isPending ? 'Loading...' : 'Login'}
					</button>
				</form>
				<Link to='/reset' className='self-center text-gray-500 text-sm pt-5'>
					Reset your password
				</Link>
				{/* {errorMessage && <AlertModal />} */}
			</div>
			<MobileLinks />
		</>
	)
}
