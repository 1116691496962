import { useParams } from 'react-router-dom'
import Navbar from '../../components/Navbar'
import { useSalesContext } from '../../hooks/useSalesContext'

const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
]

export default function SaleDetail() {
	const { userId, saleId } = useParams()
	const { sales } = useSalesContext()

	const userOrders = sales.filter(
		sale =>
			sale.randomUserID === userId && sale.monthCreated === parseInt(saleId[0])
	)

	const formatter = new Intl.NumberFormat()
	return (
		<>
			<Navbar title={`${months[parseInt(saleId[0])]} orders`} />
			{userOrders.map(item => (
				<div className='flex flex-col mb-5' key={item.id}>
					<div className='text-sm p-1 bg-slate-300'>
						<p>Date: {item.dateCreated}</p>
						<p>Order no: {item.orderNumber}</p>
						<p>Order Total: R {formatter.format(item.bagTotal)}</p>
					</div>
					<div className='bg-gray-200'>
						<p className='text-sm bg-slate-400 px-1'>Products</p>
						{item.orders.map(order => (
							<div key={order.id}>
								<p className='text-xs p-1'>{`${order.quantity}. ${order.name}`}</p>
							</div>
						))}
					</div>
				</div>
			))}
		</>
	)
}
