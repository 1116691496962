import React, { useState } from 'react'
import Navbar from '../../components/Navbar'
import NoAccess from '../../components/NoAccess'
import SalesList from './SalesList'
import { useUserContext } from '../../hooks/useUserContext'
import { useOrderContext } from '../../hooks/useOrderContext'

const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
]
export default function Sales() {
	const { activeUser } = useUserContext()
	const [search, setSearch] = useState('')
	const { orders } = useOrderContext()

	const month = new Date().getMonth()

	const monthlySales = orders
		.filter(
			item => item.monthCreated === month && item.orderStatus === 'Approved'
		)
		.map(item => item.bagTotal)
		.reduce((acc, amount) => acc + amount, 0)

	const approvedSales = orders.filter(order => order.orderStatus === 'Approved')

	const formatter = new Intl.NumberFormat()

	return (
		<div>
			<Navbar
				title={`${months[month]} sales : R ${formatter.format(monthlySales)}`}
			/>
			{activeUser?.role !== 'Admin' ? (
				<NoAccess />
			) : (
				<div className='p-2'>
					<div className='flex items-center justify-between border-b pb-1'>
						<input
							className='m-1 border w-full rounded-full pl-2 text-gray-400 py-1 outline-none'
							placeholder='search...'
							value={search}
							onChange={e => setSearch(e.target.value)}
						/>
						Search
					</div>

					{approvedSales.map(sale => (
						<SalesList
							key={sale.id}
							id={sale.id}
							userId={sale.randomUserID}
							profilePic={sale.profilePic}
							fullName={sale.fullName}
						/>
					))}
				</div>
			)}
		</div>
	)
}
