import React from 'react'
import excellence from '../../assets/excellence.png'

export default function CloseOrder({ stage }) {
	return (
		<div className='w-full flex items-center justify-center flex-col'>
			<div className='flex w-40 h-40 p-2 my-5 rounded-full shadow-sm bg-gray-100'>
				<img src={excellence} alt='pic' />
			</div>
			<p>A successful delivery!!</p>
			{stage === 5 && (
				<p className='text-xs'>this order will close in 24hours</p>
			)}
		</div>
	)
}
