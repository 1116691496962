import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AuthContextProvider } from './context/AuthContext'
import { PaymentCxtProvider } from './context/PaymentContext'
import { OrderCxtProvider } from './context/OrderContext'
import { SalesCtxProvider } from './context/SalesContext'
import { RegisterCxtProvider } from './context/RegisterContext'
import { UserCtxProvider } from './context/UserContext'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<React.StrictMode>
		<UserCtxProvider>
			<RegisterCxtProvider>
				<SalesCtxProvider>
					<OrderCxtProvider>
						<PaymentCxtProvider>
							<AuthContextProvider>
								<App />
							</AuthContextProvider>
						</PaymentCxtProvider>
					</OrderCxtProvider>
				</SalesCtxProvider>
			</RegisterCxtProvider>
		</UserCtxProvider>
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
