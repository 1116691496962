import Navbar from '../../components/Navbar'
import { useParams, useNavigate } from 'react-router-dom'
import { useOrderContext } from '../../hooks/useOrderContext'
import { IoWarning } from 'react-icons/io5'
import { useFirestore } from '../../hooks/useFirestore'
import { increment } from 'firebase/firestore'

export default function PaymentDetails() {
	const { orders } = useOrderContext()
	const { id } = useParams()
	const navigation = useNavigate()
	const { updateDocument, deleteDocument } = useFirestore('orders')
	const orderItem = orders?.filter(order => order.id === id)

	const handleApprove = id => {
		updateDocument(id, { status: 'Approved' }).then(() => navigation(-1))
	}
	const handleReject = (id, count) => {
		if (count > 1) {
			deleteDocument(id).then(() => navigation(-1))
			return
		}
		updateDocument(id, { status: 'Rejected', rejectCount: increment(1) }).then(
			() => navigation(-1)
		)
	}

	return (
		<div>
			<Navbar title='Proof of payment' />
			<div className='p-1'>
				{orderItem.map(item => (
					<div key={item.id}>
						<div className='border rounded-md flex justify-center items-center m-2'>
							<img
								src={item.paymentProof}
								alt='pop'
								className='w-fit rounded-md object-contain'
							/>
						</div>
						<div className='flex s-full p-1 mb-3'>
							<button
								onClick={() => handleApprove(item.id)}
								className='submitBtn bg-green-600'>
								Approve
							</button>
							<button
								onClick={() => handleReject(item.id, item.rejectCount)}
								className='submitBtn bg-red-600'>
								Reject
							</button>
						</div>
						<div className='flex flex-col w-full items-center bg-gray-200 p-1'>
							<div className='flex items-center w-full justify-center border-b border-black'>
								<IoWarning className='text-3xl mr-2 text-amber-500' />
								<p>Please note:</p>
							</div>
							<p className='pt-3'>
								Order by: <span className='text-red-500'>{item.fullName}</span>
							</p>
							<p>
								Order amount:
								<span className='text-red-500'> R {item.bagTotal}</span>
							</p>
							<p>
								Order no:{' '}
								<span className='text-red-500'>{item.orderNumber}</span>
							</p>
						</div>
						<div className='flex flex-col w-full items-center bg-gray-200 mt-1'>
							<p className='text-sm pt-3 text-center'>
								• Only approve payments that have reflected in the bank.
							</p>
							<p className='text-sm py-3 text-center'>
								• Make sure the order number and paid amount are the same.
							</p>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}
