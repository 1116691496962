import React from 'react'
import Navbar from '../../components/Navbar'
import EmptyState from './EmptyState'

export default function Workshops() {
	return (
		<div>
			<Navbar title='Upcoming workshops' />
			<EmptyState />
		</div>
	)
}
