import { doc, getDoc } from 'firebase/firestore'
import { useEffect } from 'react'
import { db } from './firebase/config'
import { useAuthContext } from './hooks/useAuthContext'
import { useUserContext } from './hooks/useUserContext'
import RootRoutes from './routes'

function App() {
	const { user, isAuthReady } = useAuthContext()
	const { dispatch } = useUserContext()

	useEffect(() => {
		if (user) {
			const docRef = doc(db, 'userDetails', user.uid)
			const fetchData = async () => {
				const docData = await getDoc(docRef)
				dispatch({
					type: 'LOAD_USER',
					payload: docData.data(),
				})
			}
			fetchData()
		}
	}, [user, dispatch])

	return <RootRoutes user={user} isAuthReady={isAuthReady} />
}

export default App
