import { GrWorkshop } from 'react-icons/gr'

export default function EmptyState() {
	return (
		<div className='flex justify-center items-center pt-10'>
			<div className='flex flex-col items-center'>
				<GrWorkshop className='text-9xl text-gray-400 mb-3' />
				<p>No listed workshops</p>
			</div>
		</div>
	)
}
