import React from 'react'
import { useParams } from 'react-router-dom'
import Navbar from '../../components/Navbar'
import { useSalesContext } from '../../hooks/useSalesContext'
import { useOrderContext } from '../../hooks/useOrderContext'
import NumberFormat from 'react-number-format'
import { HiOutlineChevronRight } from 'react-icons/hi'
import { Link } from 'react-router-dom'

const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
]

export default function SaleProfile() {
	const { id, userId } = useParams()
	const { sales } = useSalesContext()
	const { orders } = useOrderContext()

	const userSales = sales.filter(
		sale => sale.randomUserID === userId && sale.orderStatus === 'Approved'
	)
	const selectedUser = orders?.filter(order => order.id === id)

	const totalSaleAmount = userSales
		.map(item => item.bagTotal)
		.reduce((acc, amount) => acc + amount, 0)

	const formatter = new Intl.NumberFormat()

	const monthlyOrders = []

	userSales.forEach(item => {
		const monthCreated = item.monthCreated
		const yearCreated = item.yearCreated
		const id = `${monthCreated}${yearCreated}`
		const total = userSales
			.filter(
				item =>
					item.monthCreated === monthCreated && item.yearCreated === yearCreated
			)
			.map(item => item.bagTotal)
			.reduce((acc, price) => acc + price, 0)

		if (!monthlyOrders.map(item => item.id).includes(id)) {
			monthlyOrders.push({ id, monthCreated, yearCreated, total })
		}
	})

	return (
		<div>
			<Navbar title='Order history' />
			{selectedUser.map(user => (
				<div className='flex' key={user.id}>
					<div className='flex items-center'>
						<img
							src={user.profilePic}
							alt='pic'
							className='w-40 h-40 rounded-lg border-2 border-pink-600 m-2'
						/>
						<div className='flex flex-col text-sm'>
							<p className='text-xs text-gray-400'>Full name:</p>
							<p>{user.fullName}</p>
							<p className='text-xs text-gray-400'>Cellphone number:</p>
							<NumberFormat
								displayType={'text'}
								format='### ### ####'
								value={user.phoneNumber}
							/>
							<p className='text-xs text-gray-400'>Postal Address:</p>
							<p>{user.address}</p>
						</div>
					</div>
				</div>
			))}
			<div className='flex p-2 bg-pink-600 text-white justify-between'>
				<p>Total orders: {userSales.length}</p>
				<p>Total value: R {formatter.format(totalSaleAmount)}</p>
			</div>
			{monthlyOrders.map(item => (
				<Link
					to={item.id}
					key={item.id}
					className='flex border-b mt-1 px-2 py-1 hover:bg-gray-200 justify-between'>
					<div className='flex'>
						<p className='mr-2 text-gray-400'>{item.yearCreated}</p>
						<p>{months[item.monthCreated]}</p>
					</div>
					<div className='flex'>
						<p>R {formatter.format(item.total)}</p>
						<HiOutlineChevronRight className='text-2xl' />
					</div>
				</Link>
			))}
		</div>
	)
}
