import { HiOutlineChevronRight } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { useRegisterContext } from '../../hooks/useRegisterContext'

export default function RegistrationList({
	id,
	userId,
	username,
	status,
	profilePic,
	results,
}) {
	const { dispatch } = useRegisterContext()

	useEffect(() => {
		if (results.length) {
			dispatch({
				type: 'ANSWERS',
				payload: results,
			})
		}
	}, [results, dispatch])

	return (
		<Link
			to={`${id}/${userId}`}
			className='w-full p-2 hover:bg-gray-200 border-b flex justify-between items-center'>
			<div className='flex items-center'>
				<div className='flex mr-1'>
					<div
						className={`border h-10 w-10 rounded-full ${
							status === 'Pending' ? 'bg-gray-300' : 'bg-red-500'
						}`}>
						<img
							src={profilePic}
							alt='pic'
							className='h-10 w-10 rounded-full'
						/>
					</div>
				</div>
				<div className='flex flex-col'>
					<p className='text-sm'>{username}</p>
					<p className='text-xs text-gray-400'>{status}</p>
				</div>
			</div>
			<HiOutlineChevronRight className='text-2xl' />
		</Link>
	)
}
