import React from 'react'
import { HiOutlineChevronRight } from 'react-icons/hi'
import moment from 'moment'
import { Link } from 'react-router-dom'

export default function OrderList({
	orderNumber,
	dateCreated,
	timestamp,
	id,
	confirmed,
	stage,
}) {
	return (
		<Link
			to={id}
			className='w-full flex hover:bg-gray-200 px-1 justify-between items-center border-b'>
			<div>
				<div className='flex items-center'>
					{stage < 6 ? (
						<div
							className={`w-5 h-5 rounded-full mr-1 border ${
								stage === 5 || confirmed === 'Pending'
									? 'bg-amber-400'
									: 'bg-green-500'
							}`}
						/>
					) : (
						<div className='w-5 h-5 rounded-full mr-1 border bg-gray-500' />
					)}
					<p>{orderNumber}</p>
				</div>
				<div className='flex text-xs items-center pb-1'>
					<p>{moment(timestamp?.toDate()).fromNow()}</p>
					<p className='px-1'>|</p>
					<p className='text-gray-400'>{dateCreated}</p>
				</div>
			</div>
			<HiOutlineChevronRight className='text-2xl' />
		</Link>
	)
}
