import React from 'react'
import wait from '../../assets/wait.png'

export default function PendingOrder() {
	return (
		<div className='w-full flex items-center justify-center flex-col'>
			<div className='flex w-40 h-40 p-2 my-5 rounded-full shadow-sm bg-gray-100'>
				<img src={wait} alt='pic' />
			</div>
			<p>Awaiting client feedback</p>
		</div>
	)
}
