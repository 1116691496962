import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { useState } from 'react'
import { AiFillWarning } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { storage } from '../../firebase/config'
import { useFirestore } from '../../hooks/useFirestore'
import { v4 as uuid } from 'uuid'
import { increment } from 'firebase/firestore'

export default function ConfirmAddress({ id, confirmAddress }) {
	const [thumbnailError, setThumbnailError] = useState(null)
	const [selectedImage, setSelectedImage] = useState(null)
	const [previewImage, setPreviewImage] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const { updateDocument } = useFirestore('orders')
	const navigation = useNavigate()

	const handleSubmit = e => {
		e.preventDefault()
		setThumbnailError(null)
		setIsLoading(true)

		const obj = {
			confirmPhoto: selectedImage,
			confirmAddress: confirmAddress,
			confirmed: 'Pending',
			stage: increment(1),
		}

		if (!selectedImage) {
			setIsLoading(false)
			setThumbnailError('Please attach the waybill image')
			return
		}
		try {
			updateDocument(id, obj)
				.then(() => setIsLoading(false))
				.finally(() => navigation(-1))
		} catch (error) {
			setIsLoading(false)
			setThumbnailError(error.message)
		}
	}

	const handleFileChange = async e => {
		setIsLoading(true)
		setPreviewImage(null)
		setSelectedImage(null)
		setThumbnailError(null)
		let selected = e.target.files[0]

		if (!selected) {
			setIsLoading(false)
			setThumbnailError('Please upload an image first')
			return
		}
		if (selected.size > 1100000) {
			setIsLoading(false)
			setThumbnailError('Image size must be less than 1MB')
			return
		}

		const imagePathRef = ref(storage, `confirmPhotos/${selected.name + uuid()}`)
		await uploadBytesResumable(imagePathRef, selected)
		const url = await getDownloadURL(imagePathRef)
		setSelectedImage(url)

		const reader = new FileReader()
		reader.addEventListener('load', () => {
			setPreviewImage(reader.result)
		})
		reader.readAsDataURL(selected)
		setIsLoading(false)
	}

	return (
		<div className='w-full flex flex-col items-center'>
			<div className='flex w-44 h-44 border rounded-md my-2 bg-gray-100'>
				{previewImage && (
					<img src={previewImage} alt='waybill' className='object-cover' />
				)}
			</div>
			<div className='flex rounded-md bg-pink-600 p-1 w-fit justify-center items-center m-2'>
				<input
					type='file'
					accept='image/png, image/jpg, image/jpeg'
					required
					onChange={handleFileChange}
				/>
			</div>
			<p className='text-xs my-2'>Attach the address waybill</p>
			<div className='w-full pb-10 flex flex-col items-center pt-2 mt-1 bg-gray-300'>
				<div className='flex items-center text-sm border-b mb-5'>
					<AiFillWarning className='text-xl mr-1 text-amber-500' />
					<p>Use this address on the waybill</p>
				</div>
				<p>{confirmAddress}</p>
			</div>
			{thumbnailError && (
				<p className='text-xs mt-3 text-red-500'>{thumbnailError}</p>
			)}
			<button disabled={isLoading} onClick={handleSubmit} className='submitBtn'>
				{isLoading ? 'Loading' : 'Submit'}
			</button>
		</div>
	)
}
